import React from "react";
import { Image } from "antd-mobile";
// const group = require("../assets/WechatGroup.jpeg");
const hongbao = require("../assets/WechatIMG166.png");
const App = (props) => {
  return (
    <>
      <div style={{ position: "relative" }}>
        <Image
          style={{ position: "relative", margin: "0 auto" }}
          width={"100%"}
          height={700}
          src={hongbao}
        />
      </div>
    </>
  );
};
export default App;

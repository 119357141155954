import React, { useState, useEffect } from "react";
import { Button, FloatingBubble, Toast, Grid } from "antd-mobile";
import Video from "./Video";
import Popup from "./Popup";
import Feedback from "../Feedback";
import getZ from "../util";
import "./index.module.less";

function App() {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState();
  const [next, setNext] = useState(0); //第1个视频
  const [index, setIndex] = useState(0); //第1集
  const [allList, setAllList] = useState([]); //视频列表
  const [list, setList] = useState([]); //选集列表
  const [visible, setVisible] = useState(false);
  const [retry, setRetry] = useState(false);
  const query = window.location.search.slice(1);
  const params = new URLSearchParams(query);
  const keyword = params.get("kw");
  const handlePlay = (item) => {
    const { name, year, source } = item;
    const url = source?.eps?.[0]?.url;
    setTitle(name + "(" + year + ")");
    setUrl(url);
    if (!url) {
      Toast.show("播放失败，请稍后重试");
    }
    const array = [];
    source?.eps?.forEach((element, index) => {
      array.push({
        ...element,
        name: `第${index + 1}集`,
      });
    });
    setList(array);
  };
  const asyncFetch = () => {
    fetch(`./kw.php?z=${getZ()}&kw=${keyword}`)
      .then((response) => response.json())
      .then((res) => {
        if (res && res.code === 0 && res.result&& res.result.length) {
          setAllList(res.result);
          handlePlay(res.result[0]);
        }
      })
      .catch((err) => {
        console.log(err);
        setRetry(true)
        retry && Toast.show("播放失败，请刷新页面重试");
      });
  };
  useEffect(() => {
    keyword && asyncFetch();
    // eslint-disable-next-line
  }, [retry]);
  useEffect(() => {
    if (title) {
      if (list.length > 1) {
        document.title = "正在播放：" + title + ` 第${index + 1}集`;
        return;
      }
      document.title = "正在播放：" + title;
    }
  }, [title, index, list]);

  return (
    <div style={{ height: "40vh" }}>
      {keyword ? (
        <>
          <Video url={url} />

          <Grid columns={2} gap={4} style={{ padding: 30 }}>
            {allList?.map((item, index) => {
              const { name, year } = item;
              return (
                <Grid.Item
                  onClick={() => {
                    handlePlay(item);
                    setNext(index);
                  }}
                  key={item.name + item.year}
                >
                  <Button
                    color="primary"
                    fill={index === next ? "solid" : "outline"}
                  >
                    {`${name}(${year})`}
                  </Button>
                </Grid.Item>
              );
            })}
          </Grid>

          <FloatingBubble
            style={{
              "--initial-position-bottom": "24px",
              "--initial-position-right": "24px",
              "--edge-distance": "24px",
            }}
            onClick={() => setVisible(!visible)}
          >
            选集
          </FloatingBubble>
        </>
      ) : (
        <Feedback />
      )}
      <Popup
        visible={visible}
        setVisible={setVisible}
        list={list}
        setIndex={setIndex}
        url={url}
        setUrl={setUrl}
      ></Popup>

      <div style={{ position: "absolute", bottom: "10px", left: "30%" }}>
        {!keyword ? (
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            备案号:豫ICP备17022633号-2
          </a>
        ) : null}
      </div>
    </div>
  );
}

export default App;
